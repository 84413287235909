<template>
  <div id="app1">
    <div  @click="goPrev">
       <div class="header P_2">
        <img src="../assets/icon30.png" alt="">
        <span>同业竞争指数</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/tongyejingzhengbanner@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">同业竞争指数</div>
        <div class="txt2">{{Number(scoreItem.competition_score).toFixed(1) }} <span>分</span></div>
        <div class="model1">
            <div class="title">指数解读</div>
            <div class="subTitle">同业竞争指数是基于以下纬度生成</div>
            <div class="btns">
              <div class="btn2"  @click="goDetail(6,'同业盈利能力')" style="background-color:#E7FFE4">同业盈利能力</div>
              <div class="btn2"  @click="goDetail(6,'同业经营效率')" style="background-color:#E7FFE4" >同业经营效率</div>
              <div class="btn2"  @click="goDetail(6,'同业偿债能力')" style="background-color:#E7FFE4" >同业偿债能力</div>
              <div class="btn2"  @click="goDetail(6,'同业成长能力')" style="background-color:#E7FFE4" >同业成长能力</div>
            </div>
            <div>
              <div class="title2">本次信用测评为：<span style="color:#54BF32">{{content2}}</span> </div>
              <div class="txt">{{content4}}</div>
            </div>
            <div class="title2" style="padding-bottom:1.3rem;">生态链健康指数发展趋势为：<span style="color:#004FFF">平稳</span> </div>
        </div>
        <!-- <div class="model2">
          <div>
            <div class="title">生态链健康指数</div>
            <div class="txt"> {{content2}}</div>
          </div>
          <div  id="main" style="width: 25rem; height:10rem;"></div>
        </div> -->
        <div class="model3">
          <div>
            <div class="title">评价说明</div>
            <div class="companyTitle title" style="margin-top:0;padding:1rem 0">
              <div class="dot" style="background-color:#4DBA2A"></div>
              <div style="margin-left:0.5rem; font-weight: 600;">{{content3}}</div> 
            </div>
            <div class="txt"> {{content1}}</div>
          </div>
        </div>
        <div style="height:5rem"></div>
      </div>
    </div>
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;">返回 力度分</div>  
     
      <!-- <div class="btn" style="border-top-right-radius: 5rem;border-bottom-right-radius: 5rem;">详细信息</div>   -->
    </div>
  </div>
</template>
<script>
 import * as echarts from 'echarts';
import {mapState,} from "vuex"
export default {
   mounted(){
    let {
      credit_quality_score,
      competition_level,
      viability_score,
      transparent_operation_score,
      ecological_chain_health_score,
      competition_score,
      operational_capability_score} = this.scoreItem
    //  let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
    //  this.initEacharts(arr)
     this.getContent( competition_level)
  },
   data(){
    return {
      content1:"", //评价
      content2:"", //能力
      content3:"", //评价标题
      content4:"",
    }
  },
  computed:{
    ...mapState({
      scoreItem:  (state) => state.scoreItem,
    }),
  },
  methods:{
     goDetail(type,content){
      this.$router.push({path: "/getLmxDesc",query:{
        type,
        content,
      }})
    },
    getContent(val){
      console.log(val)
      switch (val){
        case 'A':
            this.content1 = "该企业在众多同业中独占鳌头击败了xx%的同行，有极强的竞争力，是不可错过的丛林王者企业～"  
            this.content4 = "企业竞争力极强，盈利与经营实力独占鳌头，击败了xx%的同业。"  
            this.content2 = "极好"
            this.content3 = "最强王者，独占鳌头。"  
            break;
        case 'B':
            this.content1 = "该企业在众多同业中出类拔萃击败了xx%的同行，建议投递简历～"  
            this.content4 = "企业竞争力很强，经营效率与成长能力出类拔萃，击败了xx%的同业。"  
            this.content2 = "优秀"
            this.content3 = "璀璨钻石，名列前茅。"  
            break;
        case 'C':
            this.content1 = "该企业有着高于同业平均水准的综合能力，竞争力良好。"  
            this.content4 = "企业竞争力良好，综合实力超越同业平均水准，稳步前进。"  
            this.content2 = "良好"
            this.content3 = "华贵铂金，稳步前进。"  
            break;
        case 'D':
            this.content1 = "该企业属于行业的中坚力量，可以试试看。"  
            this.content4 = "企业竞争力不错，正不断提升自身盈利及经营能力，茁壮成长。"
            this.content2 = "中等"  
            this.content3 = "荣耀黄金，奋发图强。"  
          break;
         case 'E':
            this.content1 = "该企业在同业中的竞争力还有很大的提升空间，潜力无限～"  
            this.content4 = "该企业在同业中的竞争力有极大上升空间，潜力无限。"  
            this.content2 = "较差"
            this.content3 = "坚韧黄铜，力争上游"  
          break;
        default:
        }
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
      option = {
      radar: {
        indicator: [
            { name: '信用质量评分', max: 1000,   },
            { name: '生存能力评分', max: 1000,},
            { name: '企业透明指数', max: 1000, },
            { name: '生态链健康指数', max: 1000,},
            { name: '同业竞争评分', max: 1000,color:"#4DBA2A",  },
            { name: '经营能力评分', max: 1000, }
        ],
        center: ['55%', '45%'],
        radius: 25,
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: arr,
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(165,213,149, 0.1)',
                  offset: 0 
                },
                {
                  color: 'rgba(165,213,149, 0.9)',
                  offset: 1
                }
              ])
            }
          }
      ]
      }]
    };
    myChart.setOption(option); 
    },
    goPrev(){
      this.$router.push("/reportDetail")
    },
  }
}
</script>
<style lang="scss" scoped>
#app1{
  background:#Fff;
}
.list{
  margin-top:2rem;
  display: flex;
  justify-content:space-around;
  align-items:flex-end;
  .item{
     
    .bar{
      width: 1rem;
      // height: 2rem;
       
      border-radius: 1rem;
      margin:0 auto;
    }
    .txt{
      width:2rem;
      margin-top:0.5rem;
    }
  }
}
 .companyTitle{
  display: flex;
  align-items: center;
 }
</style>